<template>
  <div
    class="credits site-credits bg-text c-gray-2 text-center padding-m z-level-3 relative"
    style="
      box-shadow: 0 0 10px 15px rgba(0, 0, 0, 0.1);
      border-top: 2px solid var(--c-gray-4);
    ">
    <div class="container-s container margin-auto">
      <a href="https://saffron-technologies.com" target="_blank" class="flex flex-center flex-middle gap-s c-gray-2">
        <img
          :src="getDynamicAssetUrl('images/logos/saffron.png')"
          style="height: 24px; width: 24px" />
        <span>{{translateTitleCase('core.siteCredits.'+type)}}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'all',
    },
  },
  data: function () {
    return {};
  },
  computed: {
    showLogo() {

    },
  }
};
</script>

<style scoped lang="scss"></style>
